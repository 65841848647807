/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.content-container {
    max-height: calc(100vh - 4.9rem);
    background-color: white;
  }

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .main-container .header .branding {
    max-width: 12rem;
  }
}

@media (max-width: 767px) {
  .modal-nav {
    display: none !important;
  }
}

.main-container .sub-nav, .main-container .subnav {
  overflow: auto;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

.main-container .sub-nav, .main-container .subnav::-webkit-scrollbar {
  display: none;   /* Chrome, Safari and Opera */
}

.main-container .content-container .content-area {
  padding: 0rem !important;
}

.clr-wizard .modal-footer {
  padding-top: 0.8rem;
}
